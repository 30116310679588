import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FetchStatus } from '../commons/Constants';
import { Logger } from '../commons/Logger';

export default class VerizonAds extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchStatus: FetchStatus.PENDING,
      verizonFeedResult: null   // Feed result
    };

    this.getAddListing = this.getAddListing.bind(this)
  }

  componentDidMount() {
    const parent = this;

    fetch(this.props.adUrl, { method: 'GET', credentials: 'omit' })
      .then(response => {
        if (response.status === 200) {
          return response.text();
        }
      })
      .then(function (responseStr) {
        parent.setState({
          fetchStatus: FetchStatus.SUCCESS,
          verizonFeedResult: new DOMParser().parseFromString(responseStr, 'text/xml')
        });
      })
      .catch(err => {
        Logger.error(err);
        parent.setState({ fetchStatus: FetchStatus.FAILURE });
      });
  }

  getAddListing() {
    let adListings
    try {
      const resultSet = (
        this.state.verizonFeedResult
        && this.state.verizonFeedResult.getElementById('adListings'))
        ? this.state.verizonFeedResult.getElementById('relatedTerms').getElementsByTagName('Listing')
        : []
      adListings = Array.from(resultSet);
    } catch (err) {
      Logger.error(err)
    }
    return adListings
  }

  renderItems(adListings) {
    return adListings && adListings.map((item, itemIndex) => {
      return (
        <li key={`verizon-feed-ad-item-${itemIndex}`}>
          <a target='_blank' style={{ color: '#fff', fontSize: 'x-large', fontWeight: 'bold', lineHeight: '2em' }}
            href={item.children[0].innerHTML}>{item.attributes[1].nodeValue.replace(/<[^>]*>?/gm, '')}
          </a>
        </li>
      )
    });
  }

  render() {
    if (this.state.fetchStatus === FetchStatus.PENDING) {
      return null
    }
    return (
      <div id='verizon-feed'>
        <ul>
          {
            this.renderItems(this.getAddListing())
          }
        </ul>
      </div>
    );
  }

}

VerizonAds.propTypes = {
  adUrl: PropTypes.string.isRequired
};
