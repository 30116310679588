import { adsLoaded, cafReturnedWithClosure, relatedLinksLoaded } from '../AdSenseCallback';
import { QueryParam, Settings, System } from '../../commons/Constants';
import { getLength, joinIfArray } from '../../commons/HelperFunctions';
import { Logger } from '../../commons/Logger';
import { buildClickTrackUrl } from '../AdSenseEvent';

// AdSense specific constants
export const AdSense = {
  defaultOption: {
    NUM_RELATED_LINKS: 3,
    NUM_SPONSORED_LISTINGS: 3,
    SEARCHBOX_DIV_ID: 'searchbox',
    RELATED_LINKS_DIV_ID: 'relatedLinks',
    SPONSORED_LISTINGS_DIV_ID: 'ads'
  },
  restriction: {
    MIN_RELATED_LINKS: 3,
    MAX_RELATED_LINKS: 10
  },
  layout: {
    SIMPLE: 'SIMPLE'
  },
  template: {
    SIMPLE_2C: 'SIMPLE_2C',
    SIMPLE_2C_LIGHT: 'SIMPLE_2C_LIGHT',
    SIMPLE_2C_EXPERIMENT: 'SIMPLE_2C_EXPERIMENT',
    LIGHT_BLUE: 'LIGHT_BLUE',
    VERTICAL_LINES: 'VERTICAL_LINES',
    PARK_WEB: 'PARK_WEB',
    DEFAULT: 'SIMPLE_2C',
    PARK_WEB_HEG: 'PARK_WEB_HEG'
  },
  page: {
    RELATED_LINKS: 'RELATED_LINKS',
    SPONSORED_LISTINGS: 'SPONSORED_LISTINGS'
  },
  dridStatus: {
    APPROVED: 'APPROVED',
    NOT_AVAILABLE: 'NOT_AVAILABLE',
    PENDING_REVIEW_PROVIDER: 'PENDING_REVIEW_PROVIDER',
    AWAITING_USER_VERIFICATION: 'AWAITING_USER_VERIFICATION',
    AWAITING_INTERNAL_REVIEW: 'AWAITING_INTERNAL_REVIEW',
    DENIED_PROVIDER: 'DENIED_PROVIDER',
    DENIED_INTERNAL: 'DENIED_INTERNAL'
  },
  adsenseStatus: {
    APPROVED: 'APPROVED',
    FAILLISTED: 'FAILLISTED',
    ADULT: 'ADULT',
    NEEDS_REVIEW: 'NEEDS_REVIEW'
  }
};

/*
 * New parking template name to old smartname.
 * This is for backward compatibility to be used in clickTrackUrl (cpclicktracking.com) in CAF
 * refer to Smartname Templates and Adsense.template
 */
export const SmartnameTemplateMap = {
  SIMPLE_2C: 'Simple2C',
  LIGHT_BLUE: 'LIGHT_BLUE'
};

/*
 * Indicates where the keywords on the park page come from
 */
export const KeywordOrigin = {
  QUERY_PARAMS: 'QUERY_PARAMS',
  DOMAIN_CONFIG: 'DOMAIN_CONFIG',
  FEED_PROVIDER: 'FEED_PROVIDER'
};

// Google CAF parameters
export const GoogleCaf = {
  // Lower cased query key name (all query param keys are lower-cased while parsing)
  queryParam: {
    AFD_TOKEN: 'afdtoken', // adsense token from the related links
    PCSA: 'pcsa', // don't know what that is, usually set to false
    QUERY: 'query', // keyword or term that was clicked or searched which resulted into sponsored listings
    SEARCH: 'search' // set to 1 if the sponsored listings is the result of a search
  },
  blockType: {
    SEARCH_BOX: 'searchbox',
    ADS: 'ads',
    TEXT_ADS: 'textads',
    RELATED_SEARCH: 'relatedsearch'
  },
  adsafe: {
    ADULT_ONLY: 'adultonly',
    MEDIUM: 'medium',
    HIGH: 'high',
    LOW: 'low'
  },
  styleId: {
    // predefined style for the ads only (does not affect the related links)
    DEFAULT: '3767353295', // other styling parameters for ad elements will be ignored
    LIGHT_BLUE: '1293544924',
    SIMPLE_2C_LIGHT: '1293544924',
    VERTICAL_LINES: '1293544924',
    PARK_WEB: '7949183650',
    PARK_WEB_HEG: '1484372058'
  }
};

/**
 * Identify AdSense page (related links or sponsored listings) from query string
 * @param queryConfig
 * @returns {string} AdSense page
 */
export function getAdSensePageType(queryConfig) {
  if (queryConfig[GoogleCaf.queryParam.QUERY] && queryConfig[GoogleCaf.queryParam.AFD_TOKEN]) {
    Logger.debug('Page = SPONSORED_LISTINGS, CAF query and afdToken found');
    return AdSense.page.SPONSORED_LISTINGS;
  }

  Logger.debug('Page = RELATED_LINKS, CAF query and afdToken not found');
  return AdSense.page.RELATED_LINKS;
}

/**
 * @description Get page option for caf parameter
 * @param landerConfig
 * @type object
 * @returns {*}
 */
export function getDefaultPageOptions(landerConfig, handlerequestAcceptedState) {
  let adsafe = GoogleCaf.adsafe.LOW;
  if (landerConfig.domain.isAdult) {
    adsafe = GoogleCaf.adsafe.ADULT_ONLY;
  }

  // lander params API is returning the full client-ID instead of pubId for CashParking domains
  let pubId = landerConfig.adSense.pubId;
  if (landerConfig.system === System.CASHPARKING) {
    Logger.debug('Updating pubId for CashParking system');
    pubId = pubId.replace('ca-', '').replace('adult_', '');
  }

  return {
    adtest: Settings.GOOGLE_ADSENSE_ADTEST,
    adsafe: adsafe,
    personalizedAds: false,
    pubId: pubId,
    domainRegistrant: landerConfig.adSense.drid,
    channel: landerConfig.adSense.channel,
    domainName: landerConfig.domain.rootDomain,
    resultsPageBaseUrl: window.location.href, // TODO: multiple search params
    pageLoadedCallback: cafReturnedWithClosure(handlerequestAcceptedState),
    styleId: GoogleCaf.styleId.DEFAULT
  };
}

/**
 * Return default related link options
 * @param landerConfig
 * @param queryConfig
 * @returns {object}
 */
export function getDefaultRelatedLinkOptions(landerConfig, queryConfig) {
  let number;
  let terms;
  let numTerms = 0;
  let optimizeTerms = false;
  const numRelatedLinks = landerConfig.lander.searchCount ? landerConfig.lander.searchCount : AdSense.defaultOption.NUM_RELATED_LINKS;

  if (typeof queryConfig[QueryParam.TERM] !== 'undefined') {
    terms = joinIfArray(queryConfig[QueryParam.TERM], ',');
    numTerms = getLength(queryConfig[QueryParam.TERM]);
  } else if (typeof landerConfig.adSense.keywords !== 'undefined') {
    terms = joinIfArray(landerConfig.adSense.keywords, ',');
    numTerms = getLength(landerConfig.adSense.keywords);
  } else {
    optimizeTerms = true;
  }

  if (numTerms > AdSense.restriction.MAX_RELATED_LINKS) {
    Logger.warn('num of terms was > ' + AdSense.restriction.MAX_RELATED_LINKS);
    numTerms = AdSense.restriction.MAX_RELATED_LINKS;
  } else if (numTerms < AdSense.restriction.MIN_RELATED_LINKS) {
    Logger.warn('num of terms was < ' + AdSense.restriction.MIN_RELATED_LINKS);
    numTerms = AdSense.restriction.MIN_RELATED_LINKS;
  }
  if (queryConfig[QueryParam.BACK_FILL] === false) {
    number = numTerms;
  } else {
    number = Math.max(numTerms, numRelatedLinks);
  }

  Logger.trace('related link: number=' + number + ' optimizeTerms=' + optimizeTerms);

  return {
    type: GoogleCaf.blockType.RELATED_SEARCH,
    linkTarget: '_top',
    columns: 1,
    number: number,
    terms: terms,
    optimizeTerms: optimizeTerms,
    container: AdSense.defaultOption.RELATED_LINKS_DIV_ID,
    adLoadedCallback: relatedLinksLoaded
  };
}

/**
 * Return default ads options
 * @param landerConfig
 * @param queryConfig
 * @returns {{type: string, container: string, number: number, lines: number, adLoadedCallback: adsLoaded, clicktrackUrl: Array}}
 */
export function getDefaultAdsOptions(landerConfig, queryConfig) {
  return {
    type: GoogleCaf.blockType.ADS,
    container: AdSense.defaultOption.SPONSORED_LISTINGS_DIV_ID,
    number: AdSense.defaultOption.NUM_SPONSORED_LISTINGS,
    lines: 2,
    adLoadedCallback: adsLoaded,
    clicktrackUrl: buildClickTrackUrl(landerConfig, queryConfig)
  };
}

/**
 * Return default searchbox options
 * @param landerConfig
 * @param queryConfig
 * @returns {object}
 */
export function getDefaultSearchboxOptions(landerConfig, queryConfig) {
  let noButton = {};
  if (queryConfig[QueryParam.SEARCH_BUTTON] === false) {
    Logger.debug("Adding 'no button' settings");
    noButton = {
      colorSearchButton: 'transparent',
      colorSearchButtonBorder: 'transparent',
      colorSearchButtonText: 'transparent'
    };
  }

  return {
    type: GoogleCaf.blockType.SEARCH_BOX,
    container: AdSense.defaultOption.SEARCHBOX_DIV_ID,
    colorBackground: 'transparent', // Area that includes the button
    hideSearchInputBorder: true,
    hideSearchButtonBorder: false, // If set to true will remove the radius
    ...noButton
  };
}

/**
 * Returns a lander template either from query parameter or lander parameter
 * If a template is not-supported, it'll return the default template for the system.
 * @param landerConfig
 * @param queryConfig
 */
export function getLanderTemplate(landerConfig, queryConfig) {
  const template = queryConfig[QueryParam.TEMPLATE] || landerConfig.lander.template;

  // check if the template is supported
  if (AdSense.template[template]) {
    return AdSense.template[template];
  }

  Logger.error('template ' + template + ' not found, using default ' + AdSense.template.DEFAULT);
  return AdSense.template.DEFAULT;
}
